import React from 'react'
import { Box, Container, IconButton, Toolbar } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { PAGES } from './config/consts'
import { HEAD_TITLE, LOGO } from './config/env'

import { AppBar, ErrorBoundary, Footer, LazyLoadPage } from './components'

import HubPage from './pages/hub'
import { Auth } from 'aws-amplify'
import { useHandleError } from './utils/hooks'

function App(): JSX.Element {
  const location = useLocation()
  const { handleError } = useHandleError()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        <AppBar position='absolute' open={false} drawerWidth={100}>
          <Toolbar
            sx={{
              pr: '24px' // keep right padding when drawer closed
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <img src={LOGO} alt={HEAD_TITLE} height={34} />
            </Box>
            <IconButton
              size='large'
              sx={{ color: '#fff' }}
              onClick={() => {
                Auth.signOut().catch(handleError)
              }}
            >
              <ExitToAppIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            overflow: 'hidden'
          }}
        >
          <Toolbar />
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
            <Container maxWidth={false} sx={{ flex: 1, pb: 2 }}>
              <LazyLoadPage>
                <Routes>
                  <Route path={PAGES.HUB.url} element={<ErrorBoundary><HubPage /></ErrorBoundary>} />
                  <Route path='*' element={<Navigate to={PAGES.HUB.url} state={{ from: location }} replace />} />
                </Routes>
              </LazyLoadPage>
            </Container>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default App
