import React from 'react'
import { Box, Typography } from '@mui/material'

import styles from './style.module.scss'

import { VERSION } from '../../config/env'

export default function Footer() {
  return (
    <Box component='footer' sx={{ px: 2, py: 2, backgroundColor: '#222', display: 'flex' }}>
      <Typography variant='body2' color='white' sx={{ flex: 1, my: 'auto' }}>
        {`© ${new Date().getFullYear()} Boa Nota`}
      </Typography>
      <Box sx={{ px: 2, display: 'flex', gap: 1 }}>
        <Typography variant='body2' color='white' className={styles.powered}>
          Powered by <a href='https://indrema.com.br/'>Indrema</a>
        </Typography>
        <Typography variant='body2' color='white' textAlign='right' className={styles.version}>
          {`v${VERSION}`}
        </Typography>
      </Box>
    </Box>
  )
}
