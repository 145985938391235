import { useEffect, useRef } from 'react'
import { useSnackbar } from 'notistack'
import axios, { AxiosError } from 'axios'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../redux/root'

export interface ResponseWithErrorMessage {
  message: string
  response: {
    data: {
      message?: string
    }
  }
}

export type ErrorType = Error | AxiosError

export function useHandleError() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // eslint-disable-next-line
  const ref = useRef<(err: Error | AxiosError) => void>((err: ErrorType) => {
    let message = err.message

    if (axios.isAxiosError(err)) {
      const error = err as ResponseWithErrorMessage
      message = error.response?.data?.message || message
    }

    enqueueSnackbar(message, {
      variant: 'error',
      style: { whiteSpace: 'pre-line' }
    })
    console.error(err)
  })

  useEffect(() => {
    const handleError = (err: Error | AxiosError) => {
      let message = err.message

      if (axios.isAxiosError(err)) {
        const error = err as ResponseWithErrorMessage
        message = error.response?.data?.message || message
      }

      enqueueSnackbar(message, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' }
      })
      console.error(err)
    }

    ref.current = handleError
  }, [enqueueSnackbar])

  return { handleError: ref.current, enqueueSnackbar, closeSnackbar }
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
