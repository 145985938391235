import React, { useEffect, useState } from 'react'
import { Container, Grid, Link, Paper, Typography } from '@mui/material'
import { IconType } from 'react-icons'

import { PRIMARY_COLOR } from '../../config/env'
import { hashObject } from '../../utils/crypto'

import allModules from './modules'
import { getUserData } from '../../services/aws'
import { useHandleError } from '../../utils/hooks'
import { LoadingContainer } from '../../components'

export default function Hub() {
  const [state, setState] = useState<{ loading: boolean, groups: string[] }>({
    loading: true,
    groups: []
  })
  const { handleError } = useHandleError()

  useEffect(() => {
    getUserData().then(data => {
      setState({ loading: false, groups: data.groups })
    }).catch(err => {
      setState({ loading: false, groups: [] })
      handleError(err)
    })
  }, [handleError])

  const myApps = allModules.filter(app => state.groups.includes(app.group))
  return (
    <Container maxWidth='lg' sx={{ minHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
      <Grid container
        spacing={2}
        sx={{ mb: 3 }}
        justifyContent='center'
      >
        {state.loading
          ? <LoadingContainer />
          : myApps.map(app => {
            const id = hashObject(app)
            return (
              <Grid item xs={12} md={6} lg={3} key={id}>
                <HubCard
                  title={app.title}
                  description={app.description}
                  icon={app.icon}
                  location={app.location}
                />
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}

export interface HubCardProps {
  location?: string
  icon: IconType
  title: string | JSX.Element
  description: string | JSX.Element
}
const HubCard = ({ location, icon, title, description }: HubCardProps) => {
  const Icon = icon

  return (
    <Link href={location} underline='none' target='_blank' rel='noopener noreferrer'>
      <Paper sx={{ p: 2, height: '100%', width: '100%' }}>
        <Grid container sx={{ background: 'primary' }}>
          <Grid item xs={12} md={4} lg={3} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              textAlign: 'center'
            }
          })}>
            <Icon size={40} color={PRIMARY_COLOR} />
          </Grid>
          <Grid item xs={12} md={8} lg={9} sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              mt: 1,
              textAlign: 'center'
            }
          })}>
            <Typography variant='h5' sx={{ color: 'primary' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant='body1'>{description}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  )
}
