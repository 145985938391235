import React from 'react'

import HomeIcon from '@mui/icons-material/Home'

export const PAGES = {
  HUB: {
    id: 'hub',
    url: '/',
    name: 'Boa Nota Hub',
    icon: <HomeIcon />
  }
}
